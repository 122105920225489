import React, { useEffect, useState, Suspense } from 'react';
import Frame from '@demo/components/Frame';
import { Button } from '@arco-design/web-react';
import { CardItem } from './components/CardItem';
import { Stack } from '@demo/components/Stack';
import { history } from '@demo/utils/history';
import { pushEvent } from '@demo/utils/pushEvent';
import templates from '@demo/config/templates.json';
import axios from 'axios';
import { useQuery } from '@demo/hooks/useQuery';
import '@demo/styles/common.scss';
import logo from './../../../../demo/public/images/Preloader.webp';

export default function Home() {
  sessionStorage.removeItem('Data');
  var [datas, setDatas] = useState([])
  var [loading, loader] = useState(true)
  const { campId, domain, store, editor, url_type } = useQuery();
  if (domain.includes('staging')) {
    sessionStorage.setItem('apiUrl', 'https://apistaging-ecom.upqor.com/call/');
  } else if (domain.includes('uat')) {
    sessionStorage.setItem('apiUrl', 'https://apiuat-ecom.upqor.com/call/');
  } else {
    sessionStorage.setItem('apiUrl', 'https://api-ecom.upqor.com/call/');
  }
  // sessionStorage.setItem('apiUrl', 'http://192.168.84.28/sit-ci-api/call/');

  const postApi = (controller, obj) => {
    return axios.post(sessionStorage.getItem('apiUrl') + controller, obj);
  }

  const defaultTemplate = [
    {
      cover: "https://editor.upqor.com/images/pendent_collection.jpg",
      name: "Pendant Collection",
      template_id: "TEMP0001",
      unique_id: "TEMP0001",
      updated_at: "2023-01-01",
      user_id: "000",
    },
    {
      cover: "https://editor.upqor.com/images/balanced_beauty.jpg",
      name: "Balanced Beauty in White Gold Collection",
      template_id: "TEMP0002",
      unique_id: "TEMP0002",
      updated_at: "2023-01-01",
      user_id: "000",
    },
    {
      cover: "https://editor.upqor.com/images/eternity_ring.jpg",
      name: "Eternity Ring Collection",
      template_id: "TEMP0003",
      unique_id: "TEMP0003",
      updated_at: "2023-01-01",
      user_id: "000",
    },
    {
      cover: "https://editor.upqor.com/images/fancy_ring.jpg",
      name: "Fancy Ring Collection",
      template_id: "TEMP0004",
      unique_id: "TEMP0004",
      updated_at: "2023-01-01",
      user_id: "000",
    },
    {
      cover: "https://editor.upqor.com/images/hallo_ring.jpg",
      name: "Hallo Ring Collection",
      template_id: "TEMP0005",
      unique_id: "TEMP0005",
      updated_at: "2023-01-01",
      user_id: "000",
    },
  ]

  const templateData = () => {
    const obj = {
      a: "GetTemplates",
      tenant_id: sessionStorage.getItem('tenant_id'),
      unique_id: '',
      template_id: '',
      SITDeveloper: '1',
      user_id: '',
      'domain': sessionStorage.getItem('domain'),
    };

    postApi("EmailTemplate", obj).then((res) => {
      if (res.data.success === 1) {
        var finalData = res['data']['data']
        finalData = finalData.concat(defaultTemplate);
        // var finalData = []
        loader(false);
        if (finalData.length != 0) {
          var finalDatas = [...templates]
          for (let c = 0; c < finalData.length; c++) {
            finalData[c]['article_id'] = finalData[c]['template_id'];
            finalDatas.push(finalData[c]);
          }
          var data1: any;
          data1 = finalDatas;
          for (let c = 0; c < data1.length; c++) {
            if (data1[c]['unique_id'] == undefined) {
              data1[c]['unique_id'] = data1[c]['article_id'];
            }
            if (data1[c]['readcount'] == undefined) {
              data1[c]['picture'] = data1[c]['cover'];
              data1[c]['title'] = data1[c]['name'];
              var newDate = new Date(data1[c]['updated_at']);
              data1[c]['created_at'] = newDate.getTime() / 1000;
            }
          }
          var temp = {
            picture: 'https://previews.123rf.com/images/yupiramos/yupiramos1901/yupiramos190101377/126464222-books-pencils-brush-back-to-school-sketch.jpg',
            title: 'Create Your Design',
            created_at: ''
          }
          data1.splice(0, 0, temp);
          setDatas(data1)
        } else {
          data1 = [];
          var temp = {
            picture: 'https://previews.123rf.com/images/yupiramos/yupiramos1901/yupiramos190101377/126464222-books-pencils-brush-back-to-school-sketch.jpg',
            title: 'Create Your Design',
            created_at: ''
          }
          data1.push(temp);
          setDatas(data1)
        }
      }
    })
  }

  const selectedTemplateData = () => {
    const obj = {
      a: "GetCampaignTemplate",
      tenant_id: sessionStorage.getItem('tenant_id'),
      entity_id: sessionStorage.getItem('entity_id'),
      campaign_id: sessionStorage.getItem('campaign_id'),
      SITDeveloper: '1',
      'domain': sessionStorage.getItem('domain'),
    };
    postApi("CampaignDetails", obj).then((res) => {
      if (res.data.success === 1) {
        loader(false);
        var select: any = [];
        select.push(res.data.data)
        console.log(JSON.parse(select[0]['json']))
        select[0]['picture'] = select[0]['cover'];
        select[0]['title'] = select[0]['name'];
        select[0]['article_id'] = select[0]['template_id'];
        select[0]['selected'] = '1';
        var datas: any = [];
        datas.push(JSON.parse(select[0]['json']));
        datas[0]['selected'] = '1';
        sessionStorage.setItem('Data', JSON.stringify(datas));
        history.replace(`/editor?id=${select[0].article_id}&userId=${select[0].user_id}&unId=${select[0].unique_id}&campId=${campId}&store=${store}&url_type=${url_type}`);
      }
    })
  }

  useEffect(() => {
    if (sessionStorage.getItem('campId') != campId) {
      const obj = {
        a: "GetEmailDomainData",
        campaign_id: campId,
        domain: domain,
        SITDeveloper: '1',
      };
      postApi("EmailTemplate", obj).then((res) => {
        if (res.data.success == 1) {
          sessionStorage.setItem('tenant_id', res.data.data.tenant_id);
          sessionStorage.setItem('entity_id', res.data.data.entity_id);
          sessionStorage.setItem('campaign_id', res.data.data.campaign_id);
          sessionStorage.setItem('campaign_name', res.data.data.campaign_name);
          sessionStorage.setItem('domain', domain);
          sessionStorage.setItem('store', store);
          sessionStorage.setItem('url_type', url_type);
          if (editor == undefined) {
            templateData();
          } else {
            selectedTemplateData();
          }
        }
      })
    } else {
      if (editor == undefined) {
        templateData();
      } else {
        selectedTemplateData();
      }
    }

  }, [])

  const onDelete = () => {
    templateData();
  }

  return (
    <>
      {loading == true ?
        <div id="loader" className="spinner">
          <img src={logo} />
        </div> :
        ''}
      {editor == undefined ?
        <Frame
          title='Templates'
          primaryAction={
            < Button
              onClick={() => {
                pushEvent({ event: 'Create' });
                history.push('/editor');
              }}
            >
              Add
            </Button>
          }
        >
          <>
            <Stack>
              {datas.map((item) => (
                <CardItem data={item} key={item} preventDelete={onDelete} />
              ))}
            </Stack>
          </>
        </Frame>
        : ''
      }
    </>
  );
}
