import React, { useEffect } from 'react';
import { Layout, Menu, Breadcrumb } from '@arco-design/web-react';
import { Stack } from '../Stack';
import { pushEvent } from '@demo/utils/pushEvent';
import { githubButtonGenerate } from '@demo/utils/githubButtonGenerate';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

interface FrameProps {
  title: string;
  breadcrumb?: React.ReactElement;
  primaryAction?: React.ReactElement;
  children: React.ReactElement;
}

export default function Frame({
  children,
  title,
  primaryAction,
  breadcrumb,
}: FrameProps) {
  useEffect(() => {
    githubButtonGenerate();
  }, []);

  return (
    <Layout>
      {/* <Header style={{ padding: '0 20px', 'background': 'white' }}>
        {primaryAction.props.children != undefined ?
          <Stack distribution='equalSpacing' alignment='center'>
            <h1 style={{ margin: '12px 0', color: 'white' }}>.</h1>
            <div>
              <Stack distribution='equalSpacing' alignment='center'>
                {primaryAction}
              </Stack>
            </div>
          </Stack>
          : ''} 
      </Header> */}
      <Layout>
        <Layout>
          <Stack vertical>
            {breadcrumb && (
              <Breadcrumb>
                <Breadcrumb.Item>{breadcrumb}</Breadcrumb.Item>
              </Breadcrumb>
            )}
            <Stack.Item>
              <Content
                style={{
                  padding: 15,
                  margin: 0,
                  backgroundColor: '#fff',
                }}
              >
                {children}
              </Content>
            </Stack.Item>
          </Stack>
        </Layout>
      </Layout>
    </Layout >
  );
}
