import { IArticle } from '@demo/services/article';
import React, { useCallback, useState } from 'react';
import { IconEdit, IconDelete } from '@arco-design/web-react/icon';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { Popconfirm } from '@arco-design/web-react';
import { Link, useHistory } from 'react-router-dom';
import template from '@demo/store/template';
import { useDispatch } from 'react-redux';
import { pushEvent } from '@demo/utils/pushEvent';
import { getLoadingByKey, useLoading } from '@demo/hooks/useLoading';
import { Loading } from '@demo/components/loading';
import axios from 'axios';
import logo from './../../../../../../demo/public/images/Preloader.webp';
interface CardItemProps {
  data: IArticle;
  preventDelete: any;
  preventSelect: any;

}

export function CardItem(props: CardItemProps) {
  const { data } = props;
  console.log(data)
  const dispatch = useDispatch();
  const history = useHistory();

  var [loading1, loader] = useState(false);

  const loading = useLoading([
    getLoadingByKey(template.loadings.duplicate, data.article_id),
    getLoadingByKey(template.loadings.removeById, data.article_id),
  ]);

  const postApi = (controller, obj) => {
    return axios.post(sessionStorage.getItem('apiUrl') + controller, obj);
  }

  const editTempate = (data) => {
    if (data.selected != '1') {
      const obj = {
        a: "GetTemplate",
        tenant_id: sessionStorage.getItem('tenant_id'),
        unique_id: data.unique_id,
        SITDeveloper: '1',
        'domain': sessionStorage.getItem('domain'),

      };
      postApi("EmailTemplate", obj).then((res) => {
        if (res.data.success === 1) {
          var datas: any = [];
          datas.push(JSON.parse(res['data']['data'].json));
          sessionStorage.setItem('Data', JSON.stringify(datas));
          setTimeout(() => {
            history.replace(`/editor?id=${data.article_id}&userId=${data.user_id}&unId=${data.unique_id}&campId=${sessionStorage.getItem('campaign_id')}&store=${sessionStorage.getItem('store')}&url_type=${sessionStorage.getItem('url_type')}`);
          }, 500);
        } else {
          history.replace(`/editor?id=${data.article_id}&userId=${data.user_id}&unId=${data.unique_id}&campId=${sessionStorage.getItem('campaign_id')}&store=${sessionStorage.getItem('store')}&url_type=${sessionStorage.getItem('url_type')}`);
        }
      })
    } else {
      var datas: any = [];
      datas.push(JSON.parse(data.json));
      datas[0]['selected'] = '1';
      sessionStorage.setItem('Data', JSON.stringify(datas));
      sessionStorage.setItem('selected', data.seleted);
      history.replace(`/editor?id=${data.article_id}&userId=${data.user_id}&unId=${data.unique_id}&campId=${sessionStorage.getItem('campaign_id')}&store=${sessionStorage.getItem('store')}&url_type=${sessionStorage.getItem('url_type')}`);
    }
  }

  const onDelete = useCallback(() => {
    const obj = {
      a: "DeleteTemplate",
      tenant_id: sessionStorage.getItem('tenant_id'),
      unique_id: data.unique_id,
      SITDeveloper: "1",
      'domain': sessionStorage.getItem('domain'),
    };

    postApi("EmailTemplate", obj).then((res) => {
      if (res.data.success === 1) {
        alert(res.data.message)
        props.preventDelete();
      }
    })
  }, [data, dispatch]);

  const onSelect = (data) => {
    if (data.user_id != '000') {
      loader(true);
      if (data.created_at == '') {
        history.replace(`/editor?campId=${sessionStorage.getItem('campaign_id')}&store=${sessionStorage.getItem('store')}&url_type=${sessionStorage.getItem('url_type')}`);
      } else {
        const obj = {
          a: "AddCampaignTemplate",
          tenant_id: sessionStorage.getItem('tenant_id'),
          entity_id: sessionStorage.getItem('entity_id'),
          campaign_id: sessionStorage.getItem('campaign_id'),
          template_id: data.unique_id,
          SITDeveloper: "1",
          'domain': sessionStorage.getItem('domain'),
        };

        postApi("CampaignDetails", obj).then((res) => {
          if (res.data.success === 1) {
            const obj = {
              a: "GetCampaignTemplate",
              tenant_id: sessionStorage.getItem('tenant_id'),
              entity_id: sessionStorage.getItem('entity_id'),
              campaign_id: sessionStorage.getItem('campaign_id'),
              SITDeveloper: '1',
              'domain': sessionStorage.getItem('domain'),
            };

            postApi("CampaignDetails", obj).then((res) => {
              if (res.data.success === 1) {
                loader(false);
                var select: any = [];
                select.push(res.data.data)
                select[0]['picture'] = select[0]['cover'];
                select[0]['title'] = select[0]['name'];
                select[0]['article_id'] = select[0]['template_id'];
                select[0]['selected'] = '1';
                var datas: any = [];
                datas.push(JSON.parse(select[0]['json']));
                datas[0]['selected'] = '1';
                console.log(datas)
                sessionStorage.setItem('Data', JSON.stringify(datas));
                history.replace(`/editor?id=${data.article_id}&userId=${data.user_id}&unId=${data.unique_id}&campId=${sessionStorage.getItem('campaign_id')}&store=${sessionStorage.getItem('store')}&url_type=${sessionStorage.getItem('url_type')}`);
              }
            })
          }
        })
      }
    } else {
      var json = {
        TEMP0001: {
          "type": "page",
          "data": {
            "value": {
              "breakpoint": "480px",
              "headAttributes": "",
              "font-size": "14px",
              "font-weight": "400",
              "line-height": "1.7",
              "headStyles": [],
              "fonts": [
                {
                  "name": "",
                  "href": "https://fonts.googleapis.com/css2?family=Roboto+Serif:opsz,wght@8..144,400;8..144,500;8..144,600;8..144,700&display=swap"
                }
              ],
              "responsive": true,
              "font-family": "Roboto Serif",
              "text-color": "#333333"
            }
          },
          "attributes": {
            "background-color": "#efeeea",
            "width": "650px"
          },
          "children": [
            {
              "type": "advanced_wrapper",
              "data": {
                "value": {}
              },
              "attributes": {
                "padding": " 0px  0px",
                "border": "none",
                "direction": "ltr",
                "text-align": "center"
              },
              "children": [
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "10px 0px 10px 0px",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "background-color": "#FFFFFF",
                    "font-family": "Arial, sans-serif"
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "border": "none",
                        "vertical-align": "middle",
                        "font-family": "Arial, sans-serif",
                        "padding": "0px 0px 0px 0px",
                        "width": "25%"
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_49991684717049768147116.png",
                            "width": "100px",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "border": "none",
                        "vertical-align": "middle",
                        "font-family": "Arial, sans-serif",
                        "padding": "0px 0px 0px 0px",
                        "width": "75%"
                      },
                      "children": [
                        {
                          "type": "navbar",
                          "data": {
                            "value": {
                              "links": [
                                {
                                  "font-size": "13px",
                                  "target": "_blank",
                                  "font-family": "",
                                  "content": "HOME",
                                  "padding": "15px 10px 15px 10px",
                                  "color": "#333333",
                                  "font-weight": "500",
                                  "href": "https://upqor.com/"
                                },
                                {
                                  "color": "#333333",
                                  "font-size": "13px",
                                  "target": "_blank",
                                  "font-family": "",
                                  "content": "ABOUT US",
                                  "padding": "15px 10px 15px 10px",
                                  "font-weight": "500",
                                  "href": "https://upqor.com/"
                                },
                                {
                                  "color": "#333333",
                                  "font-size": "13px",
                                  "target": "_blank",
                                  "font-family": "",
                                  "content": "JEWELLERY",
                                  "padding": "15px 10px 15px 10px",
                                  "font-weight": "500",
                                  "href": "https://upqor.com/"
                                },
                                {
                                  "font-size": "13px",
                                  "target": "_blank",
                                  "font-family": "",
                                  "content": "CONTACT US",
                                  "padding": "15px 10px 15px 10px",
                                  "color": "#333333",
                                  "font-weight": "500",
                                  "href": "https://upqor.com/"
                                }
                              ]
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "font-family": "Arial, sans-serif",
                            "css-class": ""
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "30px 0px 30px 0px",
                    "background-repeat": "repeat",
                    "background-size": "cover",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-color": "#ffffff",
                    "background-url": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_67995624216965751418941.jpg"
                  },
                  "children": [
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "1%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": ""
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 10px 10px",
                            "align": "left"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "59%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Pendent Collection"
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 0px 10px",
                            "align": "left",
                            "font-size": "24px",
                            "font-weight": "500"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Discover timeless beauty in our Pendent Collection. Each piece holds a story, crafted with precision and adorned with stunning gems. Elevate your style with these cherished symbols of elegance and grace."
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 0px 10px",
                            "align": "left",
                            "font-size": "14px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "Pendent Collection"
                            }
                          },
                          "attributes": {
                            "align": "left",
                            "font-weight": "600",
                            "border-radius": "0",
                            "padding": "10px 10px 10px 10px",
                            "inner-padding": "10px 25px 10px 25px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "1px solid #333333",
                            "text-align": "center",
                            "href": "-campaign_link-",
                            "color": "#333333",
                            "font-size": "13px",
                            "background-color": "transparent"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "40%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": ""
                            }
                          },
                          "attributes": {
                            "padding": "10px 25px 10px 25px",
                            "align": "left"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "10px 0px 10px 0px",
                    "background-repeat": "repeat",
                    "background-size": "cover",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-color": "#ffffff",
                    "background-url": ""
                  },
                  "children": [
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "100%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Pendent Collection"
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 0px 10px",
                            "align": "center",
                            "font-size": "22px",
                            "font-weight": "600"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Elevate your style with these cherished symbols of elegance and grace."
                            }
                          },
                          "attributes": {
                            "padding": "5px 10px 0px 10px",
                            "align": "center",
                            "font-size": "15px"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "0px 10px 10px 10px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-color": "#ffffff"
                  },
                  "children": [
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "49%",
                        "padding": "0px 0px 0px 0px",
                        "border": "1px solid #bae8e8",
                        "background-color": "#ffffff"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_67452298216965680451826.png",
                            "href": "-campaign_link-",
                            "target": "_blank",
                            "fluid-on-mobile": "true"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "18K White Gold Lab Grown Diamond Round Solitaire Pendent"
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 0px 10px",
                            "align": "center",
                            "font-weight": "500",
                            "font-size": "14px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "VIEW MORE"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "background-color": "#bae8e8",
                            "font-weight": "500",
                            "border-radius": "0",
                            "padding": "10px 10px 10px 10px",
                            "inner-padding": "10px 25px 10px 25px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "-campaign_link-",
                            "color": "#333333",
                            "font-size": "12px"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "10px",
                        "padding": "5px 0px 5px 0px",
                        "border": "none"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "49%",
                        "padding": "0px 0px 0px 0px",
                        "border": "1px solid #bae8e8",
                        "background-color": "#ffffff"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_55718271016965680482937.png",
                            "href": "-campaign_link-",
                            "target": "_blank",
                            "fluid-on-mobile": "true"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "18K White Gold Lab Grown Diamond Oval Halo Solitaire Pendent"
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 0px 10px",
                            "align": "center",
                            "font-weight": "500",
                            "font-size": "14px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "VIEW MORE"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "background-color": "#bae8e8",
                            "color": "#333333",
                            "font-weight": "500",
                            "border-radius": "0",
                            "padding": "10px 10px 10px 10px",
                            "inner-padding": "10px 25px 10px 25px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "-campaign_link-"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "0px 10px 10px 10px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-color": "#ffffff"
                  },
                  "children": [
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "49%",
                        "padding": "0px 0px 0px 0px",
                        "border": "1px solid #bae8e8",
                        "background-color": "#ffffff"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_18915329816965689789682.png",
                            "href": "-campaign_link-",
                            "target": "_blank",
                            "fluid-on-mobile": "true"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "18K White Gold Lab Grown Diamond Pear Halo Fashion Pendent"
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 0px 10px",
                            "align": "center",
                            "font-weight": "500",
                            "font-size": "14px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "VIEW MORE"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "background-color": "#bae8e8",
                            "color": "#333333",
                            "font-weight": "500",
                            "border-radius": "0",
                            "padding": "10px 10px 10px 10px",
                            "inner-padding": "10px 25px 10px 25px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "-campaign_link-"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "10px",
                        "padding": "5px 0px 5px 0px",
                        "border": "none"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "49%",
                        "padding": "0px 0px 0px 0px",
                        "border": "1px solid #bae8e8",
                        "background-color": "#ffffff"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_45635279116965689896723.png",
                            "href": "-campaign_link-",
                            "target": "_blank",
                            "fluid-on-mobile": "true"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "18K White Gold Lab Grown Diamond Round Halo Fashion Pendent"
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 0px 10px",
                            "align": "center",
                            "font-weight": "500",
                            "font-size": "14px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "VIEW MORE"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "background-color": "#bae8e8",
                            "color": "#333333",
                            "font-weight": "500",
                            "border-radius": "0",
                            "padding": "10px 10px 10px 10px",
                            "inner-padding": "10px 25px 10px 25px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "-campaign_link-"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "0px 10px 10px 10px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-color": "#ffffff"
                  },
                  "children": [
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "49%",
                        "padding": "0px 0px 0px 0px",
                        "border": "1px solid #bae8e8",
                        "background-color": "#ffffff"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_8708313541696569010969.png",
                            "href": "-campaign_link-",
                            "target": "_blank",
                            "fluid-on-mobile": "true"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": " 18K White Gold Lab Grown Diamond Oval Halo Fashion Pendent "
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 0px 10px",
                            "align": "center",
                            "font-weight": "500",
                            "font-size": "14px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "VIEW MORE"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "background-color": "#bae8e8",
                            "color": "#333333",
                            "font-weight": "500",
                            "border-radius": "0",
                            "padding": "10px 10px 10px 10px",
                            "inner-padding": "10px 25px 10px 25px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "-campaign_link-"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "10px",
                        "padding": "5px 0px 5px 0px",
                        "border": "none"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "49%",
                        "padding": "0px 0px 0px 0px",
                        "border": "1px solid #bae8e8",
                        "background-color": "#ffffff"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_16274532316965690164433.png",
                            "href": "-campaign_link-",
                            "target": "_blank",
                            "fluid-on-mobile": "true"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": " 18K White Gold Lab Grown Diamond Bezel Setting Pear Solitare Pendent "
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 0px 10px",
                            "align": "center",
                            "font-weight": "500",
                            "font-size": "14px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "VIEW MORE"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "background-color": "#bae8e8",
                            "color": "#333333",
                            "font-weight": "500",
                            "border-radius": "0",
                            "padding": "10px 10px 10px 10px",
                            "inner-padding": "10px 25px 10px 25px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "-campaign_link-"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "20px 0px 20px 0px",
                    "background-repeat": "repeat",
                    "background-size": "cover",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-color": "#ffffff",
                    "background-url": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_13454363816965700204262.png"
                  },
                  "children": [
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "1%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": ""
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 10px 10px",
                            "align": "left"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "49%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Endless Beauty"
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 0px 10px",
                            "align": "left",
                            "font-size": "20px",
                            "font-weight": "500"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Embrace beauty that never fades. Our Pendents are designed to be cherished for a lifetime."
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 10px 10px",
                            "align": "left",
                            "font-size": "15px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "Pendent Collection"
                            }
                          },
                          "attributes": {
                            "align": "left",
                            "background-color": "#bae8e8",
                            "font-weight": "600",
                            "border-radius": "0",
                            "padding": "10px 10px 10px 10px",
                            "inner-padding": "10px 25px 10px 25px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "-campaign_link-",
                            "color": "#333333",
                            "font-size": "13px"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "50%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": ""
                            }
                          },
                          "attributes": {
                            "padding": "10px 25px 10px 25px",
                            "align": "left"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "10px 0px 10px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-color": "#bae8e8"
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 0px 0px 0px",
                        "border": "none",
                        "vertical-align": "middle",
                        "width": "50%"
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 10px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_49991684717049768147116.png",
                            "width": "180px",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_social",
                          "data": {
                            "value": {
                              "elements": [
                                {
                                  "href": "mailto:info@upqor.com",
                                  "target": "_blank",
                                  "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_29382461816971008649159.jpg",
                                  "content": ""
                                },
                                {
                                  "href": "https://api.whatsapp.com/send?phone=7016789486&text=Hii",
                                  "target": "_blank",
                                  "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_74560721316971008731849.jpg",
                                  "content": ""
                                },
                                {
                                  "href": "tel:+91 70167 89486",
                                  "target": "_blank",
                                  "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_59830153416971008822851.jpg",
                                  "content": ""
                                }
                              ]
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "color": "#333333",
                            "mode": "horizontal",
                            "font-weight": "normal",
                            "border-radius": "0",
                            "padding": "0px 0px 0px 0px",
                            "inner-padding": "10px 5px 10px 5px",
                            "line-height": "",
                            "text-padding": "4px 4px 4px 4px",
                            "icon-padding": "0px",
                            "icon-size": "36px"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 0px 0px 0px",
                        "border": "none",
                        "vertical-align": "middle",
                        "width": "50%"
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Contact Us"
                            }
                          },
                          "attributes": {
                            "padding": "10px 15px 5px 15px",
                            "align": "center",
                            "font-weight": "700",
                            "color": "#333333",
                            "font-size": "24px",
                            "letter-spacing": "1px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "335, SilverStone Arcade, Singanpore, Causeway Road, Katargam, Surat, Gujarat, India. 395004"
                            }
                          },
                          "attributes": {
                            "padding": "5px 10px 0px 10px",
                            "align": "center",
                            "font-size": "13px",
                            "color": "#333333"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Tel : +91 70167 89486"
                            }
                          },
                          "attributes": {
                            "padding": "0px 10px 10px 10px",
                            "align": "center",
                            "font-size": "13px",
                            "color": "#333333"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "E: info@upqor.com"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "font-weight": "700",
                            "border-radius": "3px",
                            "padding": "0px 5px 0px 5px",
                            "inner-padding": "0px 0px 5px 0px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "mailto:info@upqor.com",
                            "background-color": "none",
                            "font-size": "12px",
                            "color": "#333333"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "Website : upqor.com"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "font-weight": "700",
                            "border-radius": "3px",
                            "padding": "0px 10px 0px 10px",
                            "inner-padding": "0px 0px 10px 0px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "https://upqor.com/",
                            "background-color": "none",
                            "font-size": "12px",
                            "color": "#333333"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-color": "#ffffff"
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 0px 0px 0px",
                        "border": "",
                        "vertical-align": "top"
                      },
                      "children": [
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "Copyright&nbsp;ⓒ 2024 UPQOR PRIVATE LIMITED"
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 10px 10px",
                            "align": "center",
                            "font-size": "14px",
                            "line-height": "",
                            "color": "#333333",
                            "container-background-color": "#ffffff",
                            "font-weight": "500"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_divider",
                  "data": {
                    "value": {}
                  },
                  "attributes": {
                    "align": "center",
                    "border-width": "1px",
                    "border-style": "solid",
                    "border-color": "#C9CCCF",
                    "padding": "0px 0px 0px 0px"
                  },
                  "children": []
                }
              ]
            },
            {
              "type": "section",
              "data": {
                "value": {
                  "noWrap": false
                }
              },
              "attributes": {
                "padding": "10px 0px 10px 0px",
                "background-repeat": "repeat",
                "background-size": "auto",
                "background-position": "top center",
                "border": "none",
                "direction": "ltr",
                "text-align": "center",
                "background-color": "#FFF"
              },
              "children": [
                {
                  "type": "column",
                  "data": {
                    "value": {}
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "border": "none",
                    "vertical-align": "top"
                  },
                  "children": [
                    {
                      "type": "unsubscribe",
                      "data": {
                        "value": {
                          "links": [
                            {
                              "content": "No longer want to receive these email? You can",
                              "color": "#000000",
                              "font-size": "13px",
                              "padding": "0px 0px",
                              "text-transform": "none"
                            },
                            {
                              "href": "-unsubscribe_link-",
                              "content": "Unsubscribe ",
                              "color": "#1890ff",
                              "font-size": "13px",
                              "target": "_blank",
                              "padding": "0px 5px",
                              "text-transform": "none",
                              "text-decoration": "underline"
                            }
                          ]
                        }
                      },
                      "attributes": {
                        "align": "center"
                      },
                      "children": []
                    }
                  ]
                }
              ]
            }
          ]
        },
        TEMP0002: {
          "type": "page",
          "data": {
            "value": {
              "breakpoint": "480px",
              "headAttributes": "",
              "line-height": "1.5",
              "headStyles": [],
              "fonts": [
                {
                  "name": "Montserrat",
                  "href": "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap"
                }
              ],
              "responsive": true,
              "font-family": "Montserrat",
              "text-color": "#111111",
              "font-size": "20px"
            }
          },
          "attributes": {
            "background-color": "#ffffff",
            "width": "650px"
          },
          "children": [
            {
              "type": "wrapper",
              "data": {
                "value": {}
              },
              "attributes": {
                "padding": "0px 0px 0px 0px",
                "border": "none",
                "direction": "ltr",
                "text-align": "center",
                "background-url": "",
                "background-color": "#f1eee9"
              },
              "children": [
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "20px 0px 0px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center"
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 0px 0px 0px",
                        "border": "none",
                        "vertical-align": "middle"
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": " 0px 0px 0px",
                            "src": "https://uq-datastorage.s3.ap-southeast-1.amazonaws.com/writable/uploads/1002/210/campaign/1002_210_campaign_47363132417049744231514.png",
                            "href": "-campaign_link-",
                            "target": "_blank",
                            "width": "190px"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center"
                  },
                  "children": [
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "100%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_1368964016988225485330.jpg",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "10px 0px 10px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center"
                  },
                  "children": [
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "90%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Balanced Beauty in White Gold Collection"
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 10px 10px",
                            "align": "center",
                            "font-weight": "700",
                            "font-size": "28px"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": true
                    }
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-color": "#f1eee9"
                  },
                  "children": [
                    {
                      "type": "group",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "vertical-align": "top",
                        "direction": "ltr"
                      },
                      "children": [
                        {
                          "type": "advanced_column",
                          "attributes": {
                            "width": "45%",
                            "padding": "0px 0px 0px 0px",
                            "vertical-align": "middle"
                          },
                          "data": {
                            "value": {}
                          },
                          "children": [
                            {
                              "type": "advanced_image",
                              "data": {
                                "value": {}
                              },
                              "attributes": {
                                "align": "center",
                                "height": "auto",
                                "padding": "0px 0px 0px 0px",
                                "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_4935726081700457635108.png",
                                "href": "-campaign_link-",
                                "target": "_blank",
                                "fluid-on-mobile": "true"
                              },
                              "children": []
                            }
                          ]
                        },
                        {
                          "type": "advanced_column",
                          "attributes": {
                            "width": "55%",
                            "padding": "0px 0px 0px 0px",
                            "vertical-align": "middle"
                          },
                          "data": {
                            "value": {}
                          },
                          "children": [
                            {
                              "type": "advanced_text",
                              "data": {
                                "value": {
                                  "content": "Crafting Elegance in Each Pendent Design"
                                }
                              },
                              "attributes": {
                                "padding": "5px 10px 0px 10px",
                                "align": "left",
                                "font-weight": "700",
                                "font-size": "16px"
                              },
                              "children": []
                            },
                            {
                              "type": "advanced_text",
                              "data": {
                                "value": {
                                  "content": "18K White Gold Lab Grown Diamond Bezel Setting Emerald Solitare Pendent"
                                }
                              },
                              "attributes": {
                                "padding": "5px 10px 5px 10px",
                                "align": "left",
                                "font-size": "14px",
                                "font-weight": "500"
                              },
                              "children": []
                            },
                            {
                              "type": "advanced_button",
                              "data": {
                                "value": {
                                  "content": "VIEW MORE"
                                }
                              },
                              "attributes": {
                                "align": "left",
                                "background-color": "#111111",
                                "color": "#ffffff",
                                "font-weight": "500",
                                "border-radius": "0",
                                "padding": "10px 10px 10px 10px",
                                "inner-padding": "10px 25px 10px 25px",
                                "line-height": "120%",
                                "target": "_blank",
                                "vertical-align": "middle",
                                "border": "none",
                                "text-align": "center",
                                "href": "-campaign_link-",
                                "font-size": "14px",
                                "letter-spacing": "1px"
                              },
                              "children": []
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": true
                    }
                  },
                  "attributes": {
                    "padding": "5px 0px 5px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center"
                  },
                  "children": [
                    {
                      "type": "group",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "vertical-align": "top",
                        "direction": "ltr"
                      },
                      "children": [
                        {
                          "type": "advanced_column",
                          "attributes": {
                            "width": "55%",
                            "padding": "0px 0px 0px 0px",
                            "vertical-align": "middle"
                          },
                          "data": {
                            "value": {}
                          },
                          "children": [
                            {
                              "type": "advanced_text",
                              "data": {
                                "value": {
                                  "content": "Elegance Captured in Every Ring Creation"
                                },
                                "hidden": false
                              },
                              "attributes": {
                                "padding": "5px 10px 0px 10px",
                                "align": "left",
                                "color": "#111111",
                                "font-weight": "700",
                                "font-family": "",
                                "line-height": "",
                                "font-size": "16px"
                              },
                              "children": []
                            },
                            {
                              "type": "advanced_text",
                              "data": {
                                "value": {
                                  "content": "18K White Gold Lab Grown Diamond Ring"
                                }
                              },
                              "attributes": {
                                "padding": "5px 10px 5px 10px",
                                "align": "left",
                                "font-size": "14px",
                                "font-weight": "500"
                              },
                              "children": []
                            },
                            {
                              "type": "advanced_button",
                              "data": {
                                "value": {
                                  "content": "VIEW MORE"
                                },
                                "hidden": false
                              },
                              "attributes": {
                                "align": "left",
                                "font-weight": "500",
                                "border-radius": "0",
                                "padding": "10px 10px 10px 10px",
                                "inner-padding": "10px 25px 10px 25px",
                                "line-height": "120%",
                                "target": "_blank",
                                "vertical-align": "middle",
                                "border": "1px solid #111111",
                                "text-align": "center",
                                "href": "-campaign_link-",
                                "font-size": "14px",
                                "color": "#ffffff",
                                "letter-spacing": "1px",
                                "font-family": "",
                                "background-color": "#111111"
                              },
                              "children": []
                            }
                          ]
                        },
                        {
                          "type": "advanced_column",
                          "attributes": {
                            "width": "45%",
                            "padding": "0px 0px 0px 0px",
                            "vertical-align": "middle"
                          },
                          "data": {
                            "value": {}
                          },
                          "children": [
                            {
                              "type": "advanced_image",
                              "data": {
                                "value": {}
                              },
                              "attributes": {
                                "align": "center",
                                "height": "auto",
                                "padding": "0px 0px 0px 0px",
                                "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_45550296817004577677379.png",
                                "href": "-campaign_link-",
                                "target": "_blank",
                                "fluid-on-mobile": "true"
                              },
                              "children": []
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": true
                    }
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center"
                  },
                  "children": [
                    {
                      "type": "group",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "vertical-align": "top",
                        "direction": "ltr"
                      },
                      "children": [
                        {
                          "type": "advanced_column",
                          "attributes": {
                            "width": "45%",
                            "padding": "0px 0px 0px 0px",
                            "vertical-align": "middle"
                          },
                          "data": {
                            "value": {}
                          },
                          "children": [
                            {
                              "type": "advanced_image",
                              "data": {
                                "value": {}
                              },
                              "attributes": {
                                "align": "center",
                                "height": "auto",
                                "padding": "0px 0px 0px 0px",
                                "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_72413719517004580282670.png",
                                "href": "-campaign_link-",
                                "target": "_blank"
                              },
                              "children": []
                            }
                          ]
                        },
                        {
                          "type": "advanced_column",
                          "attributes": {
                            "width": "55%",
                            "padding": "0px 0px 0px 0px",
                            "vertical-align": "middle"
                          },
                          "data": {
                            "value": {}
                          },
                          "children": [
                            {
                              "type": "advanced_text",
                              "data": {
                                "value": {
                                  "content": "Elegance in Every Design"
                                },
                                "hidden": false
                              },
                              "attributes": {
                                "padding": "5px 10px 0px 10px",
                                "align": "left",
                                "color": "#111111",
                                "font-family": "Montserrat",
                                "font-size": "16px",
                                "font-weight": "700",
                                "line-height": ""
                              },
                              "children": []
                            },
                            {
                              "type": "advanced_text",
                              "data": {
                                "value": {
                                  "content": " 18K White Gold Lab Grown Diamond Fancy Shape Dangle Drop Cluster Earrings "
                                }
                              },
                              "attributes": {
                                "padding": "5px 10px 10px 10px",
                                "align": "left",
                                "font-size": "14px",
                                "font-weight": "500",
                                "font-family": "Montserrat"
                              },
                              "children": []
                            },
                            {
                              "type": "advanced_button",
                              "data": {
                                "value": {
                                  "content": "VIEW MORE"
                                },
                                "hidden": false
                              },
                              "attributes": {
                                "align": "left",
                                "font-weight": "500",
                                "border-radius": "0",
                                "padding": "5px 10px 10px 10px",
                                "inner-padding": "10px 25px 10px 25px",
                                "line-height": "120%",
                                "target": "_blank",
                                "vertical-align": "middle",
                                "border": "1px solid #111111",
                                "text-align": "center",
                                "href": "-campaign_link-",
                                "background-color": "#111111",
                                "font-size": "14px",
                                "font-family": "",
                                "color": "#ffffff"
                              },
                              "children": []
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    },
                    "hidden": false
                  },
                  "attributes": {
                    "padding": "25px 0px 10px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-color": "#f1eee9"
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 0px 0px 0px",
                        "border": "none",
                        "vertical-align": "middle",
                        "width": "48%"
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 10px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_49991684717049768147116.png",
                            "width": "180px",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_social",
                          "data": {
                            "value": {
                              "elements": [
                                {
                                  "href": "mailto:info@upqor.com",
                                  "target": "_blank",
                                  "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_71119156816964999807436.jpg",
                                  "content": ""
                                },
                                {
                                  "href": "https://api.whatsapp.com/send?phone=7016789486&text=Hii",
                                  "target": "_blank",
                                  "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_5459616116964999905979.jpg",
                                  "content": ""
                                },
                                {
                                  "href": "tel:+91 70167 89486",
                                  "target": "_blank",
                                  "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_51734673016964999989994.jpg",
                                  "content": ""
                                }
                              ]
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "color": "#333333",
                            "mode": "horizontal",
                            "font-size": "13px",
                            "font-weight": "normal",
                            "border-radius": "4px",
                            "padding": "10px 10px 10px 10px",
                            "inner-padding": "0px 6px 0px 6px",
                            "line-height": "22px",
                            "text-padding": "4px 4px 4px 0px",
                            "icon-padding": "0px",
                            "icon-size": "36px"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 0px 0px 0px",
                        "border": "none",
                        "vertical-align": "middle",
                        "width": "52%"
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Contact Us"
                            }
                          },
                          "attributes": {
                            "padding": "10px 15px 5px 15px",
                            "align": "center",
                            "font-weight": "700",
                            "color": "333",
                            "font-size": "24px",
                            "letter-spacing": "1px",
                            "font-family": ""
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "335, SilverStone Arcade, Singanpore,\nCauseway Road, Katargam,\nSurat, Gujarat, India. 395004"
                            }
                          },
                          "attributes": {
                            "padding": "5px 5px 5px 5px",
                            "align": "center",
                            "color": "333",
                            "font-size": "13px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Tel : +91 70167 89486"
                            }
                          },
                          "attributes": {
                            "padding": "0px 5px 10px 5px",
                            "align": "center",
                            "color": "#333",
                            "font-size": "13px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "E: info@upqor.com"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "background-color": "none",
                            "color": "#333",
                            "font-weight": "600",
                            "border-radius": "0",
                            "padding": "0px 5px 0px 5px",
                            "inner-padding": "0px 5px 5px 5px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "mailto:info@upqor.com",
                            "font-size": "13px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "Website : upqor.com"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "color": "#333",
                            "font-weight": "600",
                            "border-radius": "3px",
                            "padding": "0px 5px 0px 5px",
                            "inner-padding": "0px 0px 10px 0px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "https://upqor.com/",
                            "background-color": "none",
                            "font-size": "13px"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "type": "advanced_divider",
              "data": {
                "value": {}
              },
              "attributes": {
                "align": "center",
                "border-width": "1px",
                "border-style": "solid",
                "border-color": "#fff",
                "padding": " 0px  0px"
              },
              "children": []
            },
            {
              "type": "section",
              "data": {
                "value": {
                  "noWrap": false
                }
              },
              "attributes": {
                "padding": "0px 0px 0px 0px",
                "background-repeat": "repeat",
                "background-size": "auto",
                "background-position": "top center",
                "border": "",
                "direction": "ltr",
                "text-align": "center",
                "background-color": "#ffffff"
              },
              "children": [
                {
                  "type": "column",
                  "data": {
                    "value": {}
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "border": "",
                    "vertical-align": "top"
                  },
                  "children": [
                    {
                      "type": "text",
                      "data": {
                        "value": {
                          "content": "Copyright&nbsp;ⓒ 2024 UPQOR PRIVATE LIMITED"
                        }
                      },
                      "attributes": {
                        "padding": "10px 15px 10px 15px",
                        "align": "center",
                        "font-size": "13px",
                        "line-height": "",
                        "color": "#fff",
                        "container-background-color": "#111111",
                        "font-family": ""
                      },
                      "children": []
                    }
                  ]
                }
              ]
            },
            {
              "type": "section",
              "data": {
                "value": {
                  "noWrap": false
                }
              },
              "attributes": {
                "padding": "10px 0px 10px 0px",
                "background-repeat": "repeat",
                "background-size": "auto",
                "background-position": "top center",
                "border": "none",
                "direction": "ltr",
                "text-align": "center",
                "background-color": "#FFF"
              },
              "children": [
                {
                  "type": "column",
                  "data": {
                    "value": {}
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "border": "none",
                    "vertical-align": "top"
                  },
                  "children": [
                    {
                      "type": "unsubscribe",
                      "data": {
                        "value": {
                          "links": [
                            {
                              "content": "No longer want to receive these email? You can",
                              "color": "#000000",
                              "font-size": "13px",
                              "padding": "0px 0px",
                              "text-transform": "none"
                            },
                            {
                              "href": "-unsubscribe_link-",
                              "content": "Unsubscribe ",
                              "color": "#1890ff",
                              "font-size": "13px",
                              "target": "_blank",
                              "padding": "0px 5px",
                              "text-transform": "none",
                              "text-decoration": "underline"
                            }
                          ]
                        }
                      },
                      "attributes": {
                        "align": "center"
                      },
                      "children": []
                    }
                  ]
                }
              ]
            }
          ]
        },
        TEMP0003: {
          "type": "page",
          "data": {
            "value": {
              "breakpoint": "480px",
              "headAttributes": "",
              "font-size": "14px",
              "line-height": "1.5",
              "headStyles": [],
              "fonts": [],
              "responsive": true,
              "font-family": "Tahoma",
              "text-color": "#000000",
              "content-background-color": "#ffffff"
            }
          },
          "attributes": {
            "background-color": "#ccc",
            "width": "650px"
          },
          "children": [
            {
              "type": "wrapper",
              "data": {
                "value": {}
              },
              "attributes": {
                "padding": "0px 0px 0px 0px",
                "border": "none",
                "direction": "ltr",
                "text-align": "center",
                "background-url": ""
              },
              "children": [
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "20px 0px 20px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-url": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_20343633116971723227039.jpg"
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 0px 0px 0px",
                        "border": "none",
                        "vertical-align": "top"
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_49991684717049768147116.png",
                            "width": "180px",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Eternity Rings: Love Lasts Forever"
                            }
                          },
                          "attributes": {
                            "padding": "20px 15px 10px 15px",
                            "align": "center",
                            "font-size": "28px",
                            "font-weight": "700",
                            "color": "#675751"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Introducing eternity ring which carries a symbolic meaning of everlasting love and is given as a gift on special occasions to express your love. So if it's your anniversary or birthday, this ring will be a perfect gift to express your never ending love towards her."
                            }
                          },
                          "attributes": {
                            "padding": "0px 20px 20px 20px",
                            "align": "center",
                            "color": "#393d47"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "VIEW THE COLLECTION"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "color": "#675751",
                            "font-weight": "600",
                            "border-radius": "0",
                            "padding": "10px 10px 25px 10px",
                            "inner-padding": "15px 25px 15px 25px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "2px solid #675751",
                            "text-align": "center",
                            "href": "-campaign_link-",
                            "font-size": "15px",
                            "letter-spacing": "0.8px",
                            "background-color": "transparent"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_95829634316971729955116.png",
                            "target": "_blank",
                            "width": "400px",
                            "href": "-campaign_link-"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-color": "#f4f1eb"
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 0px 0px 0px",
                        "border": "none",
                        "vertical-align": "top"
                      },
                      "children": [
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "Eternity Ring Collection"
                            }
                          },
                          "attributes": {
                            "padding": "10px 25px 10px 25px",
                            "align": "center",
                            "font-size": "30px",
                            "font-weight": "700",
                            "color": "#393d47"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "0px 5px 0px 5px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-color": "#f4f1eb"
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 5px 5px 5px",
                        "border": "none",
                        "vertical-align": "top"
                      },
                      "children": [
                        {
                          "type": "image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_29898122716963181461116.jpg",
                            "width": "",
                            "target": "_blank",
                            "href": "-campaign_link-",
                            "border": "",
                            "fluid-on-mobile": "true"
                          },
                          "children": []
                        },
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "Round Eternity Ring"
                            }
                          },
                          "attributes": {
                            "padding": "5px 0px 5px 0px",
                            "align": "center",
                            "font-size": "12px",
                            "line-height": "27px",
                            "font-family": "Tahoma",
                            "font-weight": "600",
                            "color": "#393d47",
                            "container-background-color": "#ffffff"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 5px 5px 5px",
                        "border": "none",
                        "vertical-align": "top"
                      },
                      "children": [
                        {
                          "type": "image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_48641117816963274322900.jpg",
                            "width": "",
                            "href": "-campaign_link-",
                            "target": "_blank",
                            "border": "",
                            "fluid-on-mobile": "true"
                          },
                          "children": []
                        },
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "Emerald Eternity Ring"
                            }
                          },
                          "attributes": {
                            "padding": "5px 0px 5px 0px",
                            "align": "center",
                            "font-size": "12px",
                            "line-height": "27px",
                            "font-family": "Tahoma",
                            "font-weight": "600",
                            "color": "#393d47",
                            "container-background-color": "#ffffff"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 5px 5px 5px",
                        "border": "none",
                        "vertical-align": "top"
                      },
                      "children": [
                        {
                          "type": "image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_17172775316963274466708.jpg",
                            "width": "",
                            "href": "-campaign_link-",
                            "target": "_blank",
                            "border": "",
                            "fluid-on-mobile": "true"
                          },
                          "children": []
                        },
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "Marquise Eternity Ring"
                            }
                          },
                          "attributes": {
                            "padding": "5px 0px 5px 0px",
                            "align": "center",
                            "font-size": "12px",
                            "line-height": "27px",
                            "font-family": "Tahoma",
                            "font-weight": "600",
                            "color": "#393d47",
                            "container-background-color": "#ffffff"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "0px 5px 10px 5px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-color": "#f4f1eb"
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "5px 5px 5px 5px",
                        "border": "none",
                        "vertical-align": "top"
                      },
                      "children": [
                        {
                          "type": "image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_65926796316963919688591.jpg",
                            "width": "",
                            "target": "_blank",
                            "href": "-campaign_link-",
                            "border": "",
                            "fluid-on-mobile": "true"
                          },
                          "children": []
                        },
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "Pear Eternity Ring"
                            }
                          },
                          "attributes": {
                            "padding": "5px 0px 5px 0px",
                            "align": "center",
                            "font-size": "12px",
                            "line-height": "27px",
                            "font-family": "Tahoma",
                            "font-weight": "600",
                            "color": "#393d47",
                            "container-background-color": "#ffffff"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "5px 5px 5px 5px",
                        "border": "none",
                        "vertical-align": "top"
                      },
                      "children": [
                        {
                          "type": "image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_74626846016963918218296.jpg",
                            "width": "",
                            "href": "-campaign_link-",
                            "target": "_blank",
                            "border": "",
                            "fluid-on-mobile": "true"
                          },
                          "children": []
                        },
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "Pear Eternity Ring"
                            }
                          },
                          "attributes": {
                            "padding": "5px 0px 5px 0px",
                            "align": "center",
                            "font-size": "12px",
                            "line-height": "27px",
                            "font-family": "Tahoma",
                            "font-weight": "600",
                            "color": "#393d47",
                            "container-background-color": "#ffffff"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "5px 5px 5px 5px",
                        "border": "none",
                        "vertical-align": "top"
                      },
                      "children": [
                        {
                          "type": "image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_76543653316963918892715.jpg",
                            "width": "",
                            "target": "_blank",
                            "href": "-campaign_link-",
                            "border": "",
                            "fluid-on-mobile": "true"
                          },
                          "children": []
                        },
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "Marquise Eternity Ring"
                            }
                          },
                          "attributes": {
                            "padding": "5px 0px 5px 0px",
                            "align": "center",
                            "font-size": "12px",
                            "line-height": "27px",
                            "font-family": "Tahoma",
                            "font-weight": "600",
                            "color": "#393d47",
                            "container-background-color": "#ffffff"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-url": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_64884226416965057309090.jpg",
                    "background-color": "#f4f1eb"
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 0px 0px 0px",
                        "border": "none",
                        "vertical-align": "middle"
                      },
                      "children": [
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "Love Lasts Forever Eternity Ring"
                            }
                          },
                          "attributes": {
                            "padding": "50px 15px 5px 15px",
                            "align": "center",
                            "font-size": "22px",
                            "line-height": "1.4",
                            "font-weight": "600",
                            "color": "#393d47"
                          },
                          "children": []
                        },
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "Introducing eternity ring which carries a symbolic meaning of everlasting love and is given as a gift on special occasions to express your love."
                            }
                          },
                          "attributes": {
                            "padding": "5px 20px 10px 20px",
                            "align": "center",
                            "font-size": "15px",
                            "line-height": "1.4",
                            "color": "#393d47"
                          },
                          "children": []
                        },
                        {
                          "type": "button",
                          "data": {
                            "value": {
                              "content": "View Collection"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "background-color": "#675751",
                            "color": "#ffffff",
                            "font-weight": "400",
                            "border-radius": "0",
                            "padding": "12px 20px 12px 20px",
                            "inner-padding": "12px 25px 12px 25px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "",
                            "text-align": "center",
                            "href": "-campaign_link-",
                            "font-size": "15px"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 0px 0px 0px",
                        "border": "none",
                        "vertical-align": "middle"
                      },
                      "children": [
                        {
                          "type": "image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_7752311516964817417469.jpg",
                            "fluid-on-mobile": "true",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "type": "section",
              "data": {
                "value": {
                  "noWrap": false
                }
              },
              "attributes": {
                "padding": "10px 0px 10px 0px",
                "background-repeat": "repeat",
                "background-size": "auto",
                "background-position": "top center",
                "border": "none",
                "direction": "ltr",
                "text-align": "center",
                "background-color": "#675751"
              },
              "children": [
                {
                  "type": "column",
                  "data": {
                    "value": {}
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "border": "none",
                    "vertical-align": "middle",
                    "width": "50%"
                  },
                  "children": [
                    {
                      "type": "advanced_image",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "align": "center",
                        "height": "auto",
                        "padding": "0px 0px 0px 0px",
                        "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_67236286717050336031402.png",
                        "width": "180px",
                        "href": "-campaign_link-",
                        "target": "_blank"
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_social",
                      "data": {
                        "value": {
                          "elements": [
                            {
                              "href": "mailto:info@upqor.com",
                              "target": "_blank",
                              "src": "https://rpdiamondsandjewellery.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_98692125216963152204298.jpg",
                              "content": ""
                            },
                            {
                              "href": "https://api.whatsapp.com/send?phone=7016789486&text=Hii",
                              "target": "_blank",
                              "src": "https://rpdiamondsandjewellery.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_43930455716963152291915.jpg",
                              "content": ""
                            },
                            {
                              "href": "tel:+91 70167 89486",
                              "target": "_blank",
                              "src": "https://rpdiamondsandjewellery.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_34356443216963152559479.jpg",
                              "content": ""
                            }
                          ]
                        }
                      },
                      "attributes": {
                        "align": "center",
                        "color": "#333333",
                        "mode": "horizontal",
                        "font-size": "13px",
                        "font-weight": "normal",
                        "border-radius": "4px",
                        "padding": "10px 10px 10px 10px",
                        "inner-padding": "0px 6px 0px 6px",
                        "line-height": "22px",
                        "text-padding": "4px 4px 4px 4px",
                        "icon-padding": "0px",
                        "icon-size": "36px"
                      },
                      "children": []
                    }
                  ]
                },
                {
                  "type": "column",
                  "data": {
                    "value": {}
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "border": "none",
                    "vertical-align": "middle",
                    "width": "50%"
                  },
                  "children": [
                    {
                      "type": "advanced_text",
                      "data": {
                        "value": {
                          "content": "Contact Us"
                        }
                      },
                      "attributes": {
                        "padding": "10px 10px 5px 10px",
                        "align": "center",
                        "font-weight": "700",
                        "color": "#f4f1eb",
                        "font-size": "24px",
                        "letter-spacing": "1px"
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_text",
                      "data": {
                        "value": {
                          "content": "335, SilverStone Arcade, Singanpore, Causeway Road, Katargam, Surat, Gujarat, India. 395004"
                        }
                      },
                      "attributes": {
                        "padding": "10px 5px 0px 5px",
                        "align": "center",
                        "color": "#f4f1eb",
                        "font-size": "12px"
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_text",
                      "data": {
                        "value": {
                          "content": "Tel : +91 70167 89486"
                        }
                      },
                      "attributes": {
                        "padding": "0px 5px 10px 5px",
                        "align": "center",
                        "color": "#f4f1eb",
                        "font-size": "12px"
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_button",
                      "data": {
                        "value": {
                          "content": "E: info@upqor.com"
                        }
                      },
                      "attributes": {
                        "align": "center",
                        "background-color": "none",
                        "color": "#ffffff",
                        "font-weight": "600",
                        "border-radius": "0",
                        "padding": "0px 5px 0px 5px",
                        "inner-padding": "0px 5px 5px 5px",
                        "line-height": "120%",
                        "target": "_blank",
                        "vertical-align": "middle",
                        "border": "none",
                        "text-align": "center",
                        "href": "mailto:rohit.vador@rpdiamondsandjewellery.com",
                        "font-size": "12px"
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_button",
                      "data": {
                        "value": {
                          "content": "Website : upqor.com"
                        }
                      },
                      "attributes": {
                        "align": "center",
                        "color": "#ffffff",
                        "font-weight": "600",
                        "border-radius": "3px",
                        "padding": "0px 5px 0px 5px",
                        "inner-padding": "0px 0px 10px 0px",
                        "line-height": "120%",
                        "target": "_blank",
                        "vertical-align": "middle",
                        "border": "none",
                        "text-align": "center",
                        "href": "https://rpdiamondsandjewellery.com",
                        "background-color": "none",
                        "font-size": "12px"
                      },
                      "children": []
                    }
                  ]
                }
              ]
            },
            {
              "type": "advanced_divider",
              "data": {
                "value": {}
              },
              "attributes": {
                "align": "center",
                "border-width": "1px",
                "border-style": "solid",
                "border-color": "#f4f1eb",
                "padding": " 0px 0px 0px"
              },
              "children": []
            },
            {
              "type": "advanced_section",
              "data": {
                "value": {
                  "noWrap": false
                }
              },
              "attributes": {
                "padding": "0px 0px 0px 0px",
                "background-repeat": "repeat",
                "background-size": "auto",
                "background-position": "top center",
                "border": "none",
                "direction": "ltr",
                "text-align": "center",
                "background-color": "#675751"
              },
              "children": [
                {
                  "type": "advanced_column",
                  "attributes": {
                    "width": "100%",
                    "padding": "0px 0px 0px 0px"
                  },
                  "data": {
                    "value": {}
                  },
                  "children": [
                    {
                      "type": "advanced_text",
                      "data": {
                        "value": {
                          "content": "Copyright&nbsp;ⓒ 2024 UPQOR PRIVATE LIMITED"
                        }
                      },
                      "attributes": {
                        "padding": "10px 5px 10px 5px",
                        "align": "center",
                        "color": "#f4f1eb",
                        "font-size": "14px"
                      },
                      "children": []
                    }
                  ]
                }
              ]
            },
            {
              "type": "section",
              "data": {
                "value": {
                  "noWrap": false
                }
              },
              "attributes": {
                "padding": "10px 0px 10px 0px",
                "background-repeat": "repeat",
                "background-size": "auto",
                "background-position": "top center",
                "border": "none",
                "direction": "ltr",
                "text-align": "center"
              },
              "children": [
                {
                  "type": "column",
                  "data": {
                    "value": {}
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "border": "none",
                    "vertical-align": "top"
                  },
                  "children": [
                    {
                      "type": "unsubscribe",
                      "data": {
                        "value": {
                          "links": [
                            {
                              "content": "No longer want to receive these email? You can",
                              "color": "#000000",
                              "font-size": "13px",
                              "padding": "0px 0px",
                              "text-transform": "none"
                            },
                            {
                              "href": "-unsubscribe_link-",
                              "content": "Unsubscribe ",
                              "color": "#1890ff",
                              "font-size": "13px",
                              "target": "_blank",
                              "padding": "0px 5px",
                              "text-transform": "none",
                              "text-decoration": "underline"
                            }
                          ]
                        }
                      },
                      "attributes": {
                        "align": "center"
                      },
                      "children": []
                    }
                  ]
                }
              ]
            }
          ]
        },
        TEMP0004: {
          "type": "page",
          "data": {
            "value": {
              "breakpoint": "480px",
              "headAttributes": "",
              "line-height": "1.5",
              "headStyles": [],
              "fonts": [
                {
                  "name": "Merriweather",
                  "href": "https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap"
                }
              ],
              "responsive": true,
              "font-family": "'Lato', sans-serif",
              "text-color": "#000000"
            }
          },
          "attributes": {
            "background-color": "#d9d9d9",
            "width": "650px"
          },
          "children": [
            {
              "type": "wrapper",
              "data": {
                "value": {},
                "hidden": false
              },
              "attributes": {
                "padding": "0px 0px 0px 0px",
                "border": "none",
                "direction": "ltr",
                "text-align": "center",
                "background-url": "",
                "background-color": "#fff"
              },
              "children": [
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    },
                    "hidden": false
                  },
                  "attributes": {
                    "padding": "20px 0px 0px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center"
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 0px 0px 0px",
                        "border": "none",
                        "vertical-align": "top"
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "15px 0px 15px 0px",
                            "src": "https://uq-datastorage.s3.ap-southeast-1.amazonaws.com/writable/uploads/1002/210/campaign/1002_210_campaign_47363132417049744231514.png",
                            "width": "190px",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center"
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 0px 0px 0px",
                        "border": "none",
                        "vertical-align": "top"
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Fancy Ring Collection"
                            }
                          },
                          "attributes": {
                            "padding": "20px 5px 0px 5px",
                            "align": "center",
                            "font-size": "46px",
                            "letter-spacing": "1px",
                            "color": "#bd202e",
                            "line-height": "120%",
                            "font-weight": "600",
                            "font-family": "Merriweather"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Elevate your style with our exquisite Fancy Ring Collection. Each piece is a fusion of opulence and craftsmanship, designed to adorn your fingers with timeless elegance. Discover the perfect statement for your unique taste."
                            }
                          },
                          "attributes": {
                            "padding": "10px 15px 10px 15px",
                            "align": "center",
                            "font-size": "16px",
                            "font-weight": "500"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "View Collection"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "background-color": "#bd202e",
                            "color": "#ffffff",
                            "font-weight": "normal",
                            "border-radius": "0",
                            "padding": "10px 10px 10px 10px",
                            "inner-padding": "12px 25px 12px 25px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "-campaign_link-",
                            "font-size": "17px",
                            "font-family": "Merriweather",
                            "letter-spacing": "1px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "20px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_18710654716965102277517.png",
                            "width": "450px",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "10px 0px  0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-color": "#fff"
                  },
                  "children": [
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "",
                        "padding": "0px 0px 0px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Celebrate Love with Rings"
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 5px 10px",
                            "align": "center",
                            "font-size": "30px",
                            "font-family": "Merriweather",
                            "font-weight": "700",
                            "color": "#bd202e"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Discover a world of romance and elegance with our exquisite ring collection. Each ring is a symbol of enduring love and timeless beauty, meticulously crafted to capture the essence of your special moments."
                            }
                          },
                          "attributes": {
                            "padding": "5px 10px 5px 10px",
                            "align": "center",
                            "font-size": "16px",
                            "color": "#333"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "padding": "15px 0px 0px 0px",
                    "background-color": "#fff"
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {},
                        "hidden": false
                      },
                      "attributes": {
                        "border": "",
                        "vertical-align": "middle",
                        "padding": "0px 0px 0px 0px",
                        "width": "33.33%",
                        "border-radius": "20"
                      },
                      "children": [
                        {
                          "type": "image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_38181601016964960428438.jpg",
                            "alt": "Chesterk tank",
                            "border": "none",
                            "padding": "0px 0px 0px 0px",
                            "fluid-on-mobile": "true",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        },
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "18K WG Lab Grown Diamond Ring"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "font-weight": "bold",
                            "color": "#333",
                            "font-size": "12px",
                            "padding": "10px 5px 10px 5px",
                            "container-background-color": "#fff"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "border": "",
                        "vertical-align": "bottom",
                        "padding": "0px 0px 0px 0px",
                        "width": "33.33%"
                      },
                      "children": [
                        {
                          "type": "image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_5271663281696496057549.jpg",
                            "alt": "Beyond backpack",
                            "border": "none",
                            "padding": " 0px  0px",
                            "fluid-on-mobile": "true",
                            "target": "_blank",
                            "href": "-campaign_link-"
                          },
                          "children": []
                        },
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "18K WG Lab Grown Diamond Ring"
                            },
                            "hidden": false
                          },
                          "attributes": {
                            "align": "center",
                            "font-weight": "bold",
                            "color": "#333",
                            "font-size": "12px",
                            "padding": "10px 5px 10px 5px",
                            "container-background-color": "#fff"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "data": {
                        "value": {},
                        "hidden": false
                      },
                      "attributes": {
                        "border": "",
                        "vertical-align": "bottom",
                        "padding": "0px 0px 0px 0px",
                        "width": "33.33%"
                      },
                      "children": [
                        {
                          "type": "image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_96314672616964974217112.jpg",
                            "alt": "Chesterk tank",
                            "border": "none",
                            "padding": "0px 0px 0px 0px",
                            "fluid-on-mobile": "true",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        },
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "18K WG Lab Grown Diamond Ring"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "font-weight": "bold",
                            "color": "#333",
                            "font-size": "12px",
                            "padding": "10px 5px 10px 5px",
                            "container-background-color": "#fff"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "padding": "0px 0px 15px 0px",
                    "background-color": "#fff"
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "border": "",
                        "vertical-align": "middle",
                        "padding": "0px 0px 0px 0px",
                        "width": "33.33%"
                      },
                      "children": [
                        {
                          "type": "image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_49140862416964974326117.jpg",
                            "alt": "Beyond backpack",
                            "border": "none",
                            "padding": " 0px  0px",
                            "fluid-on-mobile": "true",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        },
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "18K WG Lab Grown Diamond Ring"
                            },
                            "hidden": false
                          },
                          "attributes": {
                            "align": "center",
                            "font-weight": "bold",
                            "color": "#333",
                            "font-size": "12px",
                            "padding": "10px 5px 10px 5px",
                            "container-background-color": "#fff"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "data": {
                        "value": {},
                        "hidden": false
                      },
                      "attributes": {
                        "border": "",
                        "vertical-align": "middle",
                        "padding": "0px 0px 0px 0px",
                        "width": "33.33%"
                      },
                      "children": [
                        {
                          "type": "image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_18832686016964974437299.jpg",
                            "alt": "Chesterk tank",
                            "border": "none",
                            "padding": "0px 0px 0px 0px",
                            "fluid-on-mobile": "true",
                            "target": "_blank",
                            "href": "-campaign_link-"
                          },
                          "children": []
                        },
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "18K WG Lab Grown Diamond Ring"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "font-weight": "bold",
                            "color": "#333",
                            "font-size": "12px",
                            "padding": "10px 5px 10px 5px",
                            "container-background-color": "#fff"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "border": "",
                        "vertical-align": "middle",
                        "padding": "0px 0px 0px 0px",
                        "width": "33.33%"
                      },
                      "children": [
                        {
                          "type": "image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_8498798761696497512338.jpg",
                            "alt": "Beyond backpack",
                            "border": "none",
                            "padding": " 0px  0px",
                            "fluid-on-mobile": "true",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        },
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "18K WG Lab Grown Diamond Ring"
                            },
                            "hidden": false
                          },
                          "attributes": {
                            "align": "center",
                            "font-weight": "bold",
                            "color": "#333",
                            "font-size": "12px",
                            "padding": "10px 5px 10px 5px",
                            "container-background-color": "#fff"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-color": "#bd202e",
                    "padding": "15px 0px 0px 0px",
                    "background-url": ""
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "border": "none",
                        "vertical-align": "bottom",
                        "width": "1%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": ""
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 10px 10px",
                            "align": "left",
                            "color": "#FFFFFF",
                            "font-size": "13px"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "border": "none",
                        "vertical-align": "bottom",
                        "width": "49%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "children": [
                        {
                          "type": "text",
                          "data": {
                            "value": {
                              "content": "Discover a world of exquisite beauty and individuality in our Fancy Ring Collection."
                            }
                          },
                          "attributes": {
                            "align": "left",
                            "color": "#fff",
                            "font-size": "22px",
                            "padding": "5px 5px 0px 10px",
                            "line-height": "28px",
                            "font-family": "Merriweather"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Whether you're celebrating an engagement, anniversary, or simply the beauty of love itself, our rings are designed to be cherished for a lifetime."
                            }
                          },
                          "attributes": {
                            "padding": "10px 5px 10px 10px",
                            "align": "left",
                            "color": "#FFFFFF",
                            "font-size": "13px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "View Collection"
                            }
                          },
                          "attributes": {
                            "align": "left",
                            "background-color": "#fff",
                            "color": "#bd202e",
                            "font-weight": "normal",
                            "border-radius": "0",
                            "padding": "10px 5px 15px 10px",
                            "inner-padding": "10px 25px 10px 25px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "-campaign_link-",
                            "font-size": "14px",
                            "font-family": "Merriweather",
                            "letter-spacing": "1px"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "border": "none",
                        "vertical-align": "bottom",
                        "width": "50%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "children": [
                        {
                          "type": "image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "src": "https://rpdiamondsandjewellery-staging.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_2195875671696062051222.png",
                            "alt": "Clothes set",
                            "border": "none",
                            "padding": "0px 0px 0px 0px",
                            "fluid-on-mobile": "true",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    },
                    "hidden": false
                  },
                  "attributes": {
                    "padding": "25px 0px 10px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-color": "#ffffff"
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 0px 0px 0px",
                        "border": "none",
                        "vertical-align": "middle",
                        "width": "48%"
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 10px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_49991684717049768147116.png",
                            "width": "180px",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_social",
                          "data": {
                            "value": {
                              "elements": [
                                {
                                  "href": "mailto:info@upqor.com",
                                  "target": "_blank",
                                  "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_85832822817050328595698.png",
                                  "content": ""
                                },
                                {
                                  "href": "https://api.whatsapp.com/send?phone=7016789486&text=Hii",
                                  "target": "_blank",
                                  "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_7692691401705032865454.png",
                                  "content": ""
                                },
                                {
                                  "href": "tel:+91 70167 89486",
                                  "target": "_blank",
                                  "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_82740789617050328683819.png",
                                  "content": ""
                                }
                              ]
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "color": "#333333",
                            "mode": "horizontal",
                            "font-size": "13px",
                            "font-weight": "normal",
                            "border-radius": "4px",
                            "padding": "10px 10px 10px 10px",
                            "inner-padding": "0px 8px 0px 8px",
                            "line-height": "22px",
                            "text-padding": "4px 4px 4px 0px",
                            "icon-padding": "0px",
                            "icon-size": "25px"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 0px 0px 0px",
                        "border": "none",
                        "vertical-align": "middle",
                        "width": "52%"
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Contact Us"
                            }
                          },
                          "attributes": {
                            "padding": "10px 15px 5px 15px",
                            "align": "center",
                            "font-weight": "700",
                            "color": "333",
                            "font-size": "24px",
                            "letter-spacing": "1px",
                            "font-family": ""
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "335, SilverStone Arcade, Singanpore,\nCauseway Road, Katargam,\nSurat, Gujarat, India. 395004"
                            }
                          },
                          "attributes": {
                            "padding": "5px 5px 5px 5px",
                            "align": "center",
                            "color": "333",
                            "font-size": "13px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Tel : +91 70167 89486"
                            }
                          },
                          "attributes": {
                            "padding": "0px 5px 10px 5px",
                            "align": "center",
                            "color": "#333",
                            "font-size": "13px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "E: info@upqor.com"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "background-color": "none",
                            "color": "#333",
                            "font-weight": "600",
                            "border-radius": "0",
                            "padding": "0px 5px 0px 5px",
                            "inner-padding": "0px 5px 5px 5px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "mailto:info@upqor.com",
                            "font-size": "13px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "Website : upqor.com"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "color": "#333",
                            "font-weight": "600",
                            "border-radius": "3px",
                            "padding": "0px 5px 0px 5px",
                            "inner-padding": "0px 0px 10px 0px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "https://upqor.com/",
                            "background-color": "none",
                            "font-size": "13px"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "type": "advanced_divider",
              "data": {
                "value": {}
              },
              "attributes": {
                "align": "center",
                "border-width": "1px",
                "border-style": "solid",
                "border-color": "#C9CCCF",
                "padding": "0px 0px 0px 0px"
              },
              "children": []
            },
            {
              "type": "section",
              "data": {
                "value": {
                  "noWrap": false
                }
              },
              "attributes": {
                "padding": "0px 0px 0px 0px",
                "background-repeat": "repeat",
                "background-size": "auto",
                "background-position": "top center",
                "border": "none",
                "direction": "ltr",
                "text-align": "center",
                "background-color": "#ffffff"
              },
              "children": [
                {
                  "type": "column",
                  "data": {
                    "value": {}
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "border": "none",
                    "vertical-align": "top"
                  },
                  "children": [
                    {
                      "type": "text",
                      "data": {
                        "value": {
                          "content": "Copyright&nbsp;ⓒ 2024 UPQOR PRIVATE LIMITED"
                        }
                      },
                      "attributes": {
                        "padding": "10px 25px 10px 25px",
                        "align": "center",
                        "font-size": "13px",
                        "line-height": "",
                        "color": "#fff",
                        "container-background-color": "#bd202e"
                      },
                      "children": []
                    }
                  ]
                }
              ]
            },
            {
              "type": "section",
              "data": {
                "value": {
                  "noWrap": false
                }
              },
              "attributes": {
                "padding": "10px 0px 10px 0px",
                "background-repeat": "repeat",
                "background-size": "auto",
                "background-position": "top center",
                "border": "none",
                "direction": "ltr",
                "text-align": "center",
                "background-color": "#FFF"
              },
              "children": [
                {
                  "type": "column",
                  "data": {
                    "value": {}
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "border": "none",
                    "vertical-align": "top"
                  },
                  "children": [
                    {
                      "type": "unsubscribe",
                      "data": {
                        "value": {
                          "links": [
                            {
                              "content": "No longer want to receive these email? You can",
                              "color": "#000000",
                              "font-size": "13px",
                              "padding": "0px 0px",
                              "text-transform": "none"
                            },
                            {
                              "href": "-unsubscribe_link-",
                              "content": "Unsubscribe ",
                              "color": "#1890ff",
                              "font-size": "13px",
                              "target": "_blank",
                              "padding": "0px 5px",
                              "text-transform": "none",
                              "text-decoration": "underline"
                            }
                          ]
                        }
                      },
                      "attributes": {
                        "align": "center"
                      },
                      "children": []
                    }
                  ]
                }
              ]
            }
          ]
        },
        TEMP0005: {
          "type": "page",
          "data": {
            "value": {
              "breakpoint": "480px",
              "headAttributes": "",
              "line-height": "1.5",
              "headStyles": [],
              "fonts": [
                {
                  "name": "Oswald",
                  "href": "https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap"
                }
              ],
              "responsive": true,
              "font-family": "Tahoma",
              "text-color": "#000000",
              "font-size": "14px"
            }
          },
          "attributes": {
            "background-color": "#efeeea",
            "width": "650px"
          },
          "children": [
            {
              "type": "wrapper",
              "data": {
                "value": {}
              },
              "attributes": {
                "padding": "0px 0px 0px 0px",
                "border": "none",
                "direction": "ltr",
                "text-align": "center",
                "background-url": "",
                "background-color": "#fff"
              },
              "children": [
                {
                  "type": "section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "20px 0px 0px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center"
                  },
                  "children": [
                    {
                      "type": "column",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "padding": "0px 0px 0px 0px",
                        "border": "none",
                        "vertical-align": "top"
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 10px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_49991684717049768147116.png",
                            "width": "190px",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center"
                  },
                  "children": [
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "100%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "navbar",
                          "data": {
                            "value": {
                              "links": [
                                {
                                  "color": "#000",
                                  "font-size": "13px",
                                  "target": "_blank",
                                  "font-family": "Oswald",
                                  "content": "HOME",
                                  "padding": "15px 10px 15px 10px",
                                  "href": "https://upqor.com/"
                                },
                                {
                                  "color": "#000",
                                  "font-size": "13px",
                                  "target": "_blank",
                                  "font-family": "Oswald",
                                  "content": "About Us",
                                  "padding": "15px 10px 15px 10px",
                                  "href": "https://upqor.com/"
                                },
                                {
                                  "color": "#000",
                                  "font-size": "13px",
                                  "target": "_blank",
                                  "font-family": "Oswald",
                                  "content": "Jewellery",
                                  "padding": "15px 10px 15px 10px",
                                  "href": "https://upqor.com/"
                                },
                                {
                                  "src": "https://www.mailjet.com/wp-content/uploads/2016/11/ecommerce-guide.jpg",
                                  "target": "_blank",
                                  "content": "Contact Us",
                                  "color": "#000",
                                  "font-size": "13px",
                                  "font-family": "Oswald",
                                  "padding": "   ",
                                  "href": "https://upqor.com/"
                                }
                              ]
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "font-family": "Arial, sans-serif"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "60px 0px 60px 0px",
                    "background-repeat": "no-repeat",
                    "background-size": "cover",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center",
                    "background-url": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_83434742516964807943048.jpg"
                  },
                  "children": [
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "1%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": ""
                            }
                          },
                          "attributes": {
                            "padding": "10px 10px 10px 10px",
                            "align": "left"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "49%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "THE GENESIS AND POPULARITY OF HALO RING DESIGNS"
                            }
                          },
                          "attributes": {
                            "padding": "5px 10px 5px 10px",
                            "align": "left",
                            "font-family": "Oswald",
                            "color": "#fff",
                            "font-size": "24px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Make a statement with our halo promise and engagement rings complete with stunning designs that are sure to capture your heart."
                            }
                          },
                          "attributes": {
                            "padding": "5px 10px 5px 10px",
                            "align": "left",
                            "color": "#ffffff"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "View Collection"
                            }
                          },
                          "attributes": {
                            "align": "left",
                            "font-weight": "normal",
                            "border-radius": "0",
                            "padding": "5px 10px 10px 10px",
                            "inner-padding": "12px 25px 12px 25px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "-campaign_link-",
                            "font-size": "18px",
                            "font-family": "Oswald",
                            "letter-spacing": "1px",
                            "color": "#000000",
                            "background-color": "#ffffff"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "50%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": ""
                            }
                          },
                          "attributes": {
                            "padding": "10px 25px 10px 25px",
                            "align": "left"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "10px 0px 10px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center"
                  },
                  "children": [
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "100%",
                        "padding": "0px 0px 0px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "HALO RING COLLECTION"
                            }
                          },
                          "attributes": {
                            "padding": "0px 15px 0px 15px",
                            "align": "center",
                            "font-family": "Oswald",
                            "font-size": "30px",
                            "font-weight": "600"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "5px 10px 5px 10px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center"
                  },
                  "children": [
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "32.33%",
                        "padding": "0px 0px 0px 0px",
                        "border": "1px solid #ccc"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_92083716616963945683716.jpg",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "18K White Gold Diamond RING"
                            }
                          },
                          "attributes": {
                            "padding": "5px 5px 5px 5px",
                            "align": "center",
                            "font-family": "Oswald",
                            "font-size": "14px"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "1.5%",
                        "padding": "5px 0px 5px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "32.33%",
                        "padding": "0px 0px 0px 0px",
                        "border": "1px solid #ccc"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_85638912516963948539692.jpg",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "18K White Gold Diamond RING"
                            }
                          },
                          "attributes": {
                            "padding": "5px 5px 5px 5px",
                            "align": "center",
                            "font-family": "Oswald",
                            "font-size": "14px"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "1.5%",
                        "padding": "5px 0px 5px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "32.33%",
                        "padding": "0px 0px 0px 0px",
                        "border": "1px solid #ccc"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_52923526416963948585974.jpg",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "18K White Gold Diamond RING"
                            }
                          },
                          "attributes": {
                            "padding": "5px 5px 5px 5px",
                            "align": "center",
                            "font-family": "Oswald",
                            "font-size": "14px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "Shop Now"
                            },
                            "hidden": true
                          },
                          "attributes": {
                            "align": "center",
                            "background-color": "#000",
                            "color": "#ffffff",
                            "font-weight": "normal",
                            "border-radius": "0",
                            "padding": "5px 5px 10px 5px",
                            "inner-padding": "10px 15px 10px 15px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "-campaign_link-",
                            "font-family": "Oswald",
                            "letter-spacing": "1px",
                            "font-size": "14px"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "5px 10px 15px 10px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center"
                  },
                  "children": [
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "32.33%",
                        "padding": "0px 0px 0px 0px",
                        "border": "1px solid #ccc"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_44696557316963948879082.jpg",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "18K White Gold Diamond RING"
                            }
                          },
                          "attributes": {
                            "padding": "5px 5px 5px 5px",
                            "align": "center",
                            "font-family": "Oswald",
                            "font-size": "14px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "View Collection"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "background-color": "#000",
                            "color": "#ffffff",
                            "font-weight": "normal",
                            "border-radius": "0",
                            "padding": "5px 5px 10px 5px",
                            "inner-padding": "10px 15px 10px 15px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "-campaign_link-",
                            "font-family": "Oswald",
                            "letter-spacing": "1px",
                            "font-size": "14px"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "1.5%",
                        "padding": "5px 0px 5px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "32.33%",
                        "padding": "0px 0px 0px 0px",
                        "border": "1px solid #ccc"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_25996450616963948969210.jpg",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "18K White Gold Diamond RING"
                            }
                          },
                          "attributes": {
                            "padding": "5px 5px 5px 5px",
                            "align": "center",
                            "font-family": "Oswald",
                            "font-size": "14px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "View Collection"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "background-color": "#000",
                            "color": "#ffffff",
                            "font-weight": "normal",
                            "border-radius": "0",
                            "padding": "5px 5px 10px 5px",
                            "inner-padding": "10px 15px 10px 15px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "-campaign_link-",
                            "font-family": "Oswald",
                            "letter-spacing": "1px",
                            "font-size": "14px"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "1.5%",
                        "padding": "5px 0px 5px 0px"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "32.33%",
                        "padding": "0px 0px 0px 0px",
                        "border": "1px solid #ccc"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_64178453516963949103292.jpg",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "18K White Gold Diamond RING"
                            }
                          },
                          "attributes": {
                            "padding": "5px 5px 5px 5px",
                            "align": "center",
                            "font-family": "Oswald",
                            "font-size": "14px"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "View Collection"
                            }
                          },
                          "attributes": {
                            "align": "center",
                            "background-color": "#000",
                            "color": "#ffffff",
                            "font-weight": "normal",
                            "border-radius": "0",
                            "padding": "5px 5px 10px 5px",
                            "inner-padding": "10px 15px 10px 15px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "-campaign_link-",
                            "font-family": "Oswald",
                            "letter-spacing": "1px",
                            "font-size": "14px"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "advanced_section",
                  "data": {
                    "value": {
                      "noWrap": false
                    }
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "background-repeat": "repeat",
                    "background-size": "auto",
                    "background-position": "top center",
                    "border": "none",
                    "direction": "ltr",
                    "text-align": "center"
                  },
                  "children": [
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "50%",
                        "padding": "0px 0px 0px 0px",
                        "vertical-align": "middle"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "HALO RING DESIGNS"
                            }
                          },
                          "attributes": {
                            "padding": "5px 15px 5px 15px",
                            "align": "left",
                            "font-weight": "700",
                            "font-size": "28px",
                            "font-family": "Oswald"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_text",
                          "data": {
                            "value": {
                              "content": "Make a statement with our halo promise and engagement rings – complete with stunning designs that are sure to capture your heart. A halo ring is a timeless and elegant choice, beautifully designed."
                            }
                          },
                          "attributes": {
                            "padding": "10px 15px 10px 15px",
                            "align": "left",
                            "color": "#666363",
                            "font-size": "14px",
                            "font-family": "Tahoma"
                          },
                          "children": []
                        },
                        {
                          "type": "advanced_button",
                          "data": {
                            "value": {
                              "content": "View Collection"
                            }
                          },
                          "attributes": {
                            "align": "left",
                            "background-color": "#000",
                            "color": "#ffffff",
                            "font-weight": "normal",
                            "border-radius": "0",
                            "padding": "10px 15px 10px 15px",
                            "inner-padding": "12px 25px 12px 25px",
                            "line-height": "120%",
                            "target": "_blank",
                            "vertical-align": "middle",
                            "border": "none",
                            "text-align": "center",
                            "href": "-campaign_link-",
                            "font-size": "18px",
                            "font-family": "Oswald",
                            "letter-spacing": "1px"
                          },
                          "children": []
                        }
                      ]
                    },
                    {
                      "type": "advanced_column",
                      "attributes": {
                        "width": "50%",
                        "padding": "0px 0px 0px 0px",
                        "vertical-align": "middle"
                      },
                      "data": {
                        "value": {}
                      },
                      "children": [
                        {
                          "type": "advanced_image",
                          "data": {
                            "value": {}
                          },
                          "attributes": {
                            "align": "center",
                            "height": "auto",
                            "padding": "0px 0px 0px 0px",
                            "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_18089650016963329947269.jpg",
                            "fluid-on-mobile": "true",
                            "href": "-campaign_link-",
                            "target": "_blank"
                          },
                          "children": []
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "type": "section",
              "data": {
                "value": {
                  "noWrap": false
                }
              },
              "attributes": {
                "padding": "10px 0px 10px 0px",
                "background-repeat": "repeat",
                "background-size": "auto",
                "background-position": "top center",
                "border": "none",
                "direction": "ltr",
                "text-align": "center",
                "background-color": "#000"
              },
              "children": [
                {
                  "type": "column",
                  "data": {
                    "value": {}
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "border": "none",
                    "vertical-align": "middle",
                    "width": "50%"
                  },
                  "children": [
                    {
                      "type": "advanced_image",
                      "data": {
                        "value": {}
                      },
                      "attributes": {
                        "align": "center",
                        "height": "auto",
                        "padding": "0px 0px 10px 0px",
                        "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_31649315217050346745834.png",
                        "width": "180px",
                        "href": "-campaign_link-",
                        "target": "_blank"
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_social",
                      "data": {
                        "value": {
                          "elements": [
                            {
                              "href": "mailto:info@upqor.com",
                              "target": "_blank",
                              "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_11084954916963963229154.jpg",
                              "content": ""
                            },
                            {
                              "href": "https://api.whatsapp.com/send?phone=7016789486&text=Hii",
                              "target": "_blank",
                              "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_86424759516963963317136.jpg",
                              "content": ""
                            },
                            {
                              "href": "tel:+91 70167 89486",
                              "target": "_blank",
                              "src": "https://rpdiamondsandjewellery-uat.s3.ap-southeast-1.amazonaws.com/writable/uploads/1003/510/campaign/1003_510_campaign_74507724816963963391534.jpg",
                              "content": ""
                            }
                          ]
                        }
                      },
                      "attributes": {
                        "align": "center",
                        "color": "#333333",
                        "mode": "horizontal",
                        "font-size": "13px",
                        "font-weight": "normal",
                        "border-radius": "4px",
                        "padding": "10px 15px 10px 15px",
                        "inner-padding": "0px 6px 0px 6px",
                        "line-height": "22px",
                        "text-padding": "4px 4px 4px 0px",
                        "icon-padding": "0px",
                        "icon-size": "36px"
                      },
                      "children": []
                    }
                  ]
                },
                {
                  "type": "column",
                  "data": {
                    "value": {}
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "border": "none",
                    "vertical-align": "middle",
                    "width": "50%"
                  },
                  "children": [
                    {
                      "type": "advanced_text",
                      "data": {
                        "value": {
                          "content": "Contact Us"
                        }
                      },
                      "attributes": {
                        "padding": "10px 15px 5px 15px",
                        "align": "center",
                        "font-weight": "700",
                        "color": "#fff",
                        "font-size": "24px",
                        "letter-spacing": "1px"
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_text",
                      "data": {
                        "value": {
                          "content": "335, SilverStone Arcade, Singanpore, Causeway Road, Katargam, Surat, Gujarat, India. 395004"
                        }
                      },
                      "attributes": {
                        "padding": "5px 5px 0px 5px",
                        "align": "center",
                        "color": "#fff",
                        "font-size": "14px"
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_text",
                      "data": {
                        "value": {
                          "content": "Tel : +91 70167 89486"
                        }
                      },
                      "attributes": {
                        "padding": "0px 0px 5px 0px",
                        "align": "center",
                        "color": "#fff",
                        "font-size": "14px"
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_button",
                      "data": {
                        "value": {
                          "content": "E: info@upqor.com"
                        }
                      },
                      "attributes": {
                        "align": "center",
                        "color": "#ffffff",
                        "font-weight": "600",
                        "border-radius": "3px",
                        "padding": "0px 5px 0px 5px",
                        "inner-padding": "0px 0px 5px 0px",
                        "line-height": "120%",
                        "target": "_blank",
                        "vertical-align": "middle",
                        "border": "none",
                        "text-align": "center",
                        "href": "mailto:info@upqor.com",
                        "background-color": "none",
                        "font-size": "13px"
                      },
                      "children": []
                    },
                    {
                      "type": "advanced_button",
                      "data": {
                        "value": {
                          "content": "Website : upqor.com"
                        }
                      },
                      "attributes": {
                        "align": "center",
                        "color": "#ffffff",
                        "font-weight": "600",
                        "border-radius": "3px",
                        "padding": "0px 5px 0px 5px",
                        "inner-padding": "0px 0px 10px 0px",
                        "line-height": "120%",
                        "target": "_blank",
                        "vertical-align": "middle",
                        "border": "none",
                        "text-align": "center",
                        "href": "https://upqor.com/",
                        "background-color": "none",
                        "font-size": "13px"
                      },
                      "children": []
                    }
                  ]
                }
              ]
            },
            {
              "type": "advanced_divider",
              "data": {
                "value": {}
              },
              "attributes": {
                "align": "center",
                "border-width": "1px",
                "border-style": "solid",
                "border-color": "#fff",
                "padding": " 0px  0px"
              },
              "children": []
            },
            {
              "type": "section",
              "data": {
                "value": {
                  "noWrap": false
                }
              },
              "attributes": {
                "padding": "0px 0px 0px 0px",
                "background-repeat": "repeat",
                "background-size": "auto",
                "background-position": "top center",
                "border": "",
                "direction": "ltr",
                "text-align": "center",
                "background-color": "#ffffff"
              },
              "children": [
                {
                  "type": "column",
                  "data": {
                    "value": {}
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "border": "",
                    "vertical-align": "top"
                  },
                  "children": [
                    {
                      "type": "text",
                      "data": {
                        "value": {
                          "content": "Copyright&nbsp;ⓒ 2024 UPQOR PRIVATE LIMITED"
                        }
                      },
                      "attributes": {
                        "padding": "10px 15px 10px 15px",
                        "align": "center",
                        "font-size": "13px",
                        "line-height": "",
                        "color": "#fff",
                        "container-background-color": "#000"
                      },
                      "children": []
                    }
                  ]
                }
              ]
            },
            {
              "type": "section",
              "data": {
                "value": {
                  "noWrap": false
                }
              },
              "attributes": {
                "padding": "10px 0px 10px 0px",
                "background-repeat": "repeat",
                "background-size": "auto",
                "background-position": "top center",
                "border": "none",
                "direction": "ltr",
                "text-align": "center",
                "background-color": "#ffffff"
              },
              "children": [
                {
                  "type": "column",
                  "data": {
                    "value": {}
                  },
                  "attributes": {
                    "padding": "0px 0px 0px 0px",
                    "border": "none",
                    "vertical-align": "top"
                  },
                  "children": [
                    {
                      "type": "unsubscribe",
                      "data": {
                        "value": {
                          "links": [
                            {
                              "content": "No longer want to receive these email? You can",
                              "color": "#000000",
                              "font-size": "13px",
                              "padding": "0px 0px",
                              "text-transform": "none"
                            },
                            {
                              "href": "-unsubscribe_link-",
                              "content": "Unsubscribe ",
                              "color": "#1890ff",
                              "font-size": "13px",
                              "target": "_blank",
                              "padding": "0px 5px",
                              "text-transform": "none",
                              "text-decoration": "underline"
                            }
                          ]
                        }
                      },
                      "attributes": {
                        "align": "center"
                      },
                      "children": []
                    }
                  ]
                }
              ]
            }
          ]
        }
      }
      var arr = [
        {
          article_id: data.template_id,
          category_id: '000',
          content:
          {
            article_id: data.template_id,
            content: JSON.stringify(json[data['template_id']])
          },
          picture: data.picture,
          selected: 1,
          title: data.name,
          summary: data.name,
        }
      ];
      sessionStorage.setItem('Data', JSON.stringify(arr));
      history.replace(`/editor?id=${data.article_id}&userId=${data.user_id}&unId=${data.unique_id}&campId=${sessionStorage.getItem('campaign_id')}&store=${sessionStorage.getItem('store')}&url_type=${sessionStorage.getItem('url_type')}`);
    }
  }

  const onDuplicate: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (ev) => {
      ev.preventDefault();
      dispatch(
        template.actions.duplicate({
          article: data,
          _actionKey: data.article_id,
          success(id) {
            history.push(`/editor?id=${id}`);
          },
        })
      );
    },
    [data, dispatch, history]
  );

  return (
    <>
      {loading1 == true ?
        <div id="loader" className="spinner">
          <img src={logo} />
        </div> :
        ''}
      <div
        key={data.article_id}
        className={styles.templeteItem}
        style={{ backgroundImage: `url(${data.picture})` }}
      >
        <div className={styles.bottom}>
          <div className={styles.title}>{data.title}</div>
          {data.created_at != '' ?
            <div className={styles.title}>
              Date {dayjs(data.created_at * 1000).format('YYYY-MM-DD')}
            </div>
            : ''}
        </div>
        <div className={styles.mask}>
          {loading ? (
            <div className={styles.listBottom}>
              <Loading loading color='#ffffff' />
            </div>
          ) : (
            <div className={styles.listBottom}>

              {/* <div className={styles.listItem}>
                {data.selected != '1' ?
                  <Popconfirm
                    title='Are you want to delete it?'
                    onConfirm={onDelete}
                    okText='Ok'
                    cancelText='Cancel'
                  >
                    <IconDelete />
                    &nbsp;Delete
                  </Popconfirm>
                  :
                  'Remove Selected'}
              </div>
              <div className={styles.listItem} onClick={(e) => { editTempate(data) }}>

                <IconEdit />
                &nbsp;Edit
              </div> */}
              {/* <div className={styles.listItem}>
                <Link to='javascript:void(0)' onClick={onDuplicate}>
                  Duplicate
                </Link>
              </div> */}
              {data.selected != '1' ?
                <div className={styles.listItem}>
                  <span onClick={() => onSelect(data)}>
                    {data.created_at == '' ?
                      'Add'
                      :
                      <>
                        {data.user_id == '000' ?
                          'View'
                          : 'Select'
                        }
                      </>
                    }
                  </span>
                </div>
                :
                <div className={styles.listItem} onClick={(e) => { editTempate(data) }}>
                  <IconEdit />
                  &nbsp;Edit
                </div>
              }
            </div>
          )}
        </div>
      </div>
    </>
  );
}
