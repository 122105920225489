import { request } from './axios.config';
import axios from 'axios';

const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/dwkp0e1yo/image/upload';

export const common = {
  async uploadByQiniu(file: File) {
    const obj =
    {
      'a': 'uploadImage',
      'tenant_id': sessionStorage.getItem('tenant_id'),
      'entity_id': sessionStorage.getItem('entity_id'),
      'SITDeveloper': '1',
      'domain': sessionStorage.getItem('domain'),
    }
    var domain: any;
    domain = sessionStorage.getItem('domain');
    const data = new FormData();
    data.append('image', file, file.name);
    data.append('domain', domain);
    data.append('json', JSON.stringify(obj));
    const res = await axios.post(sessionStorage.getItem('apiUrl') + 'Company', data);
    return (res.data.data.path)
  },
  uploadByUrl(url: string) {
    return request.get<string>('/upload/user/upload-by-url', {
      params: {
        url,
      },
    });
  },
  getMenu(): Promise<IAppMenuItem[]> {
    return Promise.resolve([
      {
        name: '数据模板',
        icon: 'bar-chart',
        isOpen: true,
        children: [
          {
            name: '数据模板',
            url: '/',
          },
        ],
      },
    ]);
  },
  sendTestEmail(data: { toEmail: string; subject: string; html: string; text: string }) {
    return request.post('/email/user/send', {
      to_email: data.toEmail,
      subject: data.subject,
      text: data.text,
      html: data.html,
    });
  },
};

export interface IAppMenuItem {
  name: string;
  url?: string;
  icon: string;
  isOpen?: boolean;
  children: IAppSubMenuItem[];
}

export interface IAppSubMenuItem {
  name: string;
  url: string;
  isOpen?: boolean;
}
